import React, { Component } from "react";
import {
  Layout,
  Form,
  FormLayout,
  TextField,
  Checkbox,
  Button,
  Icon
} from '@shopify/polaris';
import { ViewMinor, HideMinor } from '@shopify/polaris-icons';

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      remember: false,
      errors: { email: null, password: null },
      hidden: true,
      loading: false
    };
  }

  /**
   *
   * @param {string} field
   * @param {string} value
   */
  onInputChange(field, value) {
    this.setState({
      [field]: value,
      errors: {[field]: null}
    });
  }

  /**
   *
   * @param {string} field
   * @param {string} message
   * @returns false
   */
  setError(field, message) {
    this.setState({ errors: {[field]: message}});
    return false;
  }

  onSubmit() {
    if (!this.validate()) return;

    this.setState({ loading: true }, () => {
      axios.post(route('login.post'), {
        email: this.state.email,
        password: this.state.password,
        remember: this.state.remember
      })
        .then(response => window.location.href = response.request.responseURL)
        .catch(error => {
          this.setState({
            errors: {...error.response.data.errors},
            loading: false
          });
        });
    });
  }

  /**
   *
   * @returns {boolean}
   */
  validate() {
    if (!this.state.email.length) {
      return this.setError('email', 'Enter your email');
    }

    if (!this.state.email.trim().length) {
      return this.setError('email', 'Incorrect email');
    }

    if (!this.state.password.length) {
      return this.setError('password', 'Enter your password');
    }

    if (!this.state.password.trim().length) {
      return this.setError('password', 'Incorrect password');
    }

    return true;
  }

  toggleVisibility() {
    this.setState({ hidden: !this.state.hidden });
  }

  render() {
    return (
      <Layout>
        <Form onSubmit={() => this.onSubmit()}>
          <div className="logo" />
          <FormLayout>
            <TextField
              onChange={value => this.onInputChange('email', value)}
              value={this.state.email}
              error={this.state.errors.email}
              label="Email"
              type="email"
            />
            <TextField
              connectedRight={
                <Button
                  icon={<Icon source={this.state.hidden ? ViewMinor : HideMinor} color="black" />}
                  onClick={() => this.toggleVisibility()}
                  plain
                />
              }
              onChange={value => this.onInputChange('password', value)}
              value={this.state.password}
              error={this.state.errors.password}
              label="Password"
              type={this.state.hidden ? 'password' : 'text'}
            />
            <Checkbox
              label="Remember me"
              checked={this.state.remember}
              onChange={value => this.setState({ remember: value })}
            />
            <Button submit primary loading={this.state.loading}>Enter</Button>
          </FormLayout>
        </Form>
      </Layout>
    );
  }
}
