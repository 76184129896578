import React from 'react';
import { Layout, Card, Stack, SkeletonBodyText, Icon, DisplayText } from '@shopify/polaris';
import AppItem from "./components/App/AppItem";
import StatusesBar from "./components/StatusesBar";
import { CircleTickOutlineMinor } from "@shopify/polaris-icons";

export default class InfoForUsers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      apps: [],
      loading: true
    };
  }

  componentDidMount() {
    this.refreshList();
  }

  /**
   *
   * @return {null|React.Component}
   */
  get allOkStatus() {
    if (!this.isAllAppsOk()) {
      return null;
    }

    return (
      <div className="all-ok-status">
        <Icon source={CircleTickOutlineMinor} color="success" />
        <DisplayText size="extraLarge">No known issues</DisplayText>
      </div>
    );
  }

  /**
   *
   * @return {array}
   */
  get apps() {
    return [
      this.state.apps.slice(0, Math.ceil(this.state.apps.length / 2)),
      this.state.apps.slice(Math.ceil(this.state.apps.length / 2))
    ];
  }

  get list() {
    if (this.state.loading) {
      return <SkeletonBodyText />;
    }

    return (
      <div className="apps-list">
        <Stack>{this.apps.map((apps, i) => this.getAppsCol(apps, i))}</Stack>
      </div>
    );
  }

  /**
   *
   * @return {boolean}
   */
  isAllAppsOk() {
    if (!this.state.apps.length) {
      return false;
    }

    return !this.state.apps.some(app => app.status !== 'ok');
  }

  /**
   *
   * @param {array} items
   * @param {number} i
   * @return {JSX.Element}
   */
  getAppsCol(items, i) {
    return <div key={i} className="apps-col">{items.map((item, i) => <AppItem key={i} app={item} />)}</div>;
  }

  refreshList() {
    this.setState({ loading: true }, () => {
      axios.get(route('api.apps.index'))
        .then(response => this.setState({ loading: false, apps: response.data }))
        .catch(e => console.log(e));
    });
  }

  render() {
    return (
      <Layout>
        <Layout.Section>
          {this.allOkStatus}
          <Card title="Status by the app">
            <Card.Section>
              <hr />
              {this.list}
              <StatusesBar />
            </Card.Section>
          </Card>
          <p className="subtext-notice">
            Some issues affecting a small percentage of stores may not be reflected here.
          </p>
        </Layout.Section>
      </Layout>
    );
  }
}
