import React from "react";
import PropTypes from 'prop-types';
import {Thumbnail} from "@shopify/polaris";

export default class AppIcon extends React.Component {
    constructor(props) {
        super(props);
    }

    static get propTypes() {
        return {
            name: PropTypes.string,
            alt: PropTypes.string
        }
    }

    static get defaultProps() {
        return {
            name: null,
            alt: null
        };
    }

    render() {
        if (!this.props.name) {
            return null;
        }

        return <Thumbnail source={this.props.name} size="small" alt={this.props.alt}/>;
    }
}
