import Login from "../pages/auth/Login";
import InfoForUsers from "../pages/InfoForUsers";

export default {
    login: {
        path: route('login'),
        component: Login
    },
    info_for_users: {
        path: route('guest-info'),
        component: InfoForUsers
    }
};
