import React from "react";
import { Frame, Page } from "@shopify/polaris";
import PropTypes from "prop-types";

export default class GuestPage extends React.Component {
  constructor(props) {
    super(props);
    this.refCurrentPage = React.createRef();

    this.state = {
      primaryAction: true,
      title: props.title,
      mobileNav: false
    }
  }

  static get propTypes() {
    return {
      className: PropTypes.string,
      title: PropTypes.string,
      breadcrumbs: PropTypes.arrayOf(PropTypes.shape({
        content: PropTypes.string.isRequired,
        onAction: PropTypes.func
      })),
      primaryAction: PropTypes.shape({
        content: PropTypes.string.isRequired,
        onAction: PropTypes.func.isRequired
      }),
    };
  }

  static get defaultProps() {
    return {
      className: 'some',
      title: '',
      breadcrumbs: [],
      primaryAction: null,
    }
  }

  /**
   *
   * @return {JSX.Element}
   */
  get navigation() {
    return null;
  }

  /**
   *
   * @return {JSX.Element}
   */
  get header() {
    return (
      <div className="guest-header">
        <div className="guest-header__logo" />
      </div>
    );
  }

  /**
   *
   * @return {JSX.Element}
   */
  get topBar() {
    return null;
  }

  /**
   *
   * @return {object|null}
   */
  get logo() {
    return null;
  }

  toggleMobileNav() {
    this.setState({ mobileNav: !this.state.mobileNav });
  }

  /**
   *
   * @return {Object}
   */
  get primaryAction() {
    if (!this.props.primaryAction || !this.state.primaryAction) {
      return null;
    }

    const primaryAction = Object.assign(
      { ...this.props.primaryAction },
      { onAction: () => this.props.primaryAction.onAction(this.refCurrentPage.current) }
    );

    return primaryAction;
  }

  /**
   *
   * @param {Object} state
   */
  setPageState (state) {
    this.setState(state);
  }

  /**
   *
   * @return {JSX.Element}
   */
  get specificPage() {
    const Component = this.props.component;
    return <Component
      {...this.props}
      ref={this.refCurrentPage}
      setPageState={state => this.setPageState(state)}
    />
  }

  render() {
    return <Frame
      onNavigationDismiss={() => this.toggleMobileNav()}
      showMobileNavigation={this.state.mobileNav}
      navigation={this.navigation}
      topBar={this.topBar}
      logo={this.logo}
    >
      <div className={this.props.className}>
        <Page
          title={this.state.title}
          breadcrumbs={this.props.breadcrumbs}
          primaryAction={this.primaryAction}
        >
          {this.header}
          {this.specificPage}
        </Page>
      </div>
    </Frame>
  }
}
