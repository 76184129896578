import React from "react";
import { render } from 'react-dom';
import routes from './routes/guest';
import { AppProvider } from '@shopify/polaris';
import PagesList from "./components/routing/PagesList";
import { BrowserRouter } from "react-router-dom";
import enTranslations from '@shopify/polaris/locales/en.json';
import GuestPage from "./pages/GuestPage";

window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

(() => {
  render(
    <BrowserRouter>
      <AppProvider i18n={enTranslations}>
        <PagesList routes={routes} page={GuestPage} />
      </AppProvider>
    </BrowserRouter>,
    document.getElementById('app')
  );
})();
