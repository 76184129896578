import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Stack, TextStyle } from "@shopify/polaris";
import {
  CircleMinusMajor,
  ClockMajor,
  CircleAlertMajor,
  MobileAcceptMajor
} from "@shopify/polaris-icons";

const ICONS = {
  ok: MobileAcceptMajor,
  maintenance: ClockMajor,
  degraded: CircleAlertMajor,
  error: CircleMinusMajor,
}

const COLORS = {
  ok: 'success',
  maintenance: 'interactive',
  degraded: 'warning',
  error: 'critical',
}

const TEXTS = {
  ok: 'No known issues',
  maintenance: 'Maintenance',
  degraded: 'Degraded',
  error: 'Outage',
}

export default class AppStatus extends React.Component {
  constructor(props) {
    super(props);
  }

  static get propTypes() {
    return {
      status: PropTypes.oneOf(['ok', 'maintenance', 'degraded', 'error'])
    };
  }

  /**
   *
   * @return {React.FunctionComponent<React.SVGProps<SVGSVGElement>>}
   */
  get icon() {
    return ICONS[this.props.status] || ICONS['error'];
  }

  /**
   *
   * @return {string}
   */
  get iconColor() {
    return COLORS[this.props.status] || COLORS['error'];
  }

  /**
   *
   * @return {string}
   */
  get text() {
    return TEXTS[this.props.status] || TEXTS['error'];
  }

  render() {
    return (
      <div className="app-status">
        <Stack alignment="center">
          <Icon source={this.icon} color={this.iconColor} />
          <TextStyle variation="subdued">{this.text}</TextStyle>
        </Stack>
      </div>
    );
  }
}
