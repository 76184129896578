import React, {Component} from "react";
import {Page, EmptyState, Layout} from "@shopify/polaris";

export default class NotFound extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Page>
                <Layout>
                    <Layout.Section>
                        <EmptyState
                            heading="Page not found"
                            image="https://cdn.shopify.com/s/files/1/0757/9955/files/empty-state.svg"
                        />
                    </Layout.Section>
                </Layout>
            </Page>
        );
    }
}
