import React from "react";
import AppStatus from "./App/AppStatus";
import {Stack} from "@shopify/polaris";

export default class StatusesBar extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="app-statuses">
                <Stack alignment="center" distribution="trailing">
                    <AppStatus status="ok"/>
                    <AppStatus status="maintenance"/>
                    <AppStatus status="degraded"/>
                    <AppStatus status="error"/>
                </Stack>
            </div>
        );
    }
}
