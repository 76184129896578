import React from 'react';
import PropTypes from 'prop-types';
import {Heading, Link, Stack, Thumbnail} from '@shopify/polaris';
import AppStatus from "./AppStatus";
import AppIcon from "../../../../components/AppIcon";

export default class AppItem extends React.Component {
    constructor(props) {
        super(props);
    }

    static propTypes() {
        return {
            app: PropTypes.object
        };
    }

    /**
     *
     * @return {object}
     */
    get app() {
        return this.props.app;
    }

    /**
     *
     * @return {React.Component}
     */
    get title() {
        return (
            <div className="app-title">
                <Stack alignment="center">
                    <AppIcon name={this.app.icon} alt={this.app.name}/>
                    <Heading>{this.app.name}</Heading>
                </Stack>
            </div>
        );
    }

    render() {
        return (
            <div className="app-item">
                <Link url={this.app.link} removeUnderline monochrome external>
                  {this.title}
                </Link>
                <AppStatus status={this.app.status}/>
            </div>
        );
    }
}
