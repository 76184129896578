import React, { Component } from "react";
import * as PropTypes from 'prop-types';
import { Switch, Route } from "react-router-dom";
import NotFound from "../../pages/errors/NotFound";

export default class PagesList extends Component {
  constructor(props) {
    super(props);
  }

  static get propTypes() {
    return {
      routes: PropTypes.object,
      page: PropTypes.elementType
    };
  }

  getList() {
    let i = 0;
    const list = [];
    const routes = this.props.routes;
    const Page = this.props.page;

    for (let key in routes) {
      if (!routes.hasOwnProperty(key)) {
        continue;
      }

      list.push(
        <Route key={i++} path={routes[key].path || '/'} exact render={routerProps =>
          <Page routes={this.props.routes} isOwner={this.props.isOwner} className={`${key}-page`} {...routes[key]} {...routerProps} />
        } />
      );
    }

    list.push(
      <Route key={i} path="*">
        <Page className="not-found" component={NotFound} />
      </Route>
    );

    return list;
  }

  render() {
    return <Switch>{this.getList()}</Switch>;
  }
}
